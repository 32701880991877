
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/tooltip.scss';
    @import '~/scss/slider.scss';
  
.headerContainer {
  .header {
    padding: 12px 16px;
    background-color: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

    @include breakpoint(medium) {
      padding: 16px 32px;
    }

    @include breakpoint(large) {
      padding: 16px 64px;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .secondaryNavLinks {
    margin-right: 20px;
    margin-left: auto;
    color: $color-dark-blue;
    font-family: $font-axiforma;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.04em;
    line-height: 1;

    @media (max-width: 640px) {
      display: none;
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
}

.leftNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightNav {
  .btnMobile {
    border-radius: 30px;
    background: #005dac;
    font-family: $font-axiforma;
    @media only screen and (min-width: 740px) {
      display: none;
    }
  }

  .btnDesktop {
    display: none;
    border-radius: 30px;
    background: #005dac;
    font-family: $font-axiforma;

    @include breakpoint(small) {
      display: flex;
    }
  }

  .btnMobile,
  .btnDesktop {
    width: 131px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.04em;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  a {
    text-decoration: none;
  }
  .phoneNumber {
    display: none;
    height: 48px;
    padding: 16px;
    border-radius: 30px;
    background: #005dac;
    color: $color-white;
    font-family: $font-axiforma;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.04em;
    cursor: pointer;

    @media only screen and (min-width: 740px) {
      display: flex;
    }

    &:focus {
      outline: 2px dashed #787878;
    }

    &:hover {
      border-style: solid;
      border-color: #113c72;
      background-color: #113c72;
      color: $color-white;

      &:hover {
        svg > path {
          fill: $color-white;
        }
      }
    }

    svg {
      position: relative;
      margin-right: 5px;
    }

    span {
      margin-left: 8px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
}

.navLinks {
  display: none;
  margin-left: 40px;
  color: $color-medium-gray;
  font-family: $font-primary;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;

  @include breakpoint(medium) {
    display: flex;
  }
}

.logoBlue {
  top: 1px;
  width: 120px;
  height: 33px;
  margin-left: 0;
  cursor: pointer;

  @include breakpoint(medium) {
    width: 160px;
    height: 45px;
  }
}

.addressWrapper {
  position: fixed;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 500ms ease-in-out;
  background: #005dac;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  text-align: -webkit-center;
}

.topNav {
  top: 56px;

  @media (min-width: 640px) {
    top: 77px;
  }
}

.bottomNav {
  bottom: 0;
}

.mozilla {
  text-align: -moz-center;
}

.show {
  height: 94px;
  overflow: visible;
}
