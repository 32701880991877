
    @import '@red-digital/react-devkit/scss/foundation/util/util';
    @import '~/scss/theme.scss';
    @import '~/scss/tooltip.scss';
    @import '~/scss/slider.scss';
  
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  border: transparent;
  border-radius: 50px;
  background: linear-gradient(
    180deg,
    $color-primary-blue 0,
    $color-dark-blue 100%
  );
  color: $color-white;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  @include breakpoint(large) {
    &:hover {
      border: 2px solid $color-primary-blue;
      background: transparent;
      color: $color-primary-blue;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}

.secondary {
  border: 2px solid $color-white;
  background: transparent;
  color: white;

  @include breakpoint(large) {
    &:hover {
      border: none;
      background: linear-gradient(
        180deg,
        $color-primary-blue 0,
        $color-dark-blue 100%
      );
      color: white;
    }
  }
}

.orange {
  background: $color-orange;
  color: white;

  @include breakpoint(large) {
    &:hover {
      border: 2px solid $color-orange;
      background: transparent;
      color: $color-orange;
    }
  }
}

.gray {
  background: $color-dark-gray;

  @include breakpoint(large) {
    &:hover {
      border: unset;
      background: $color-dark-gray;
      color: $color-white;
    }
  }
}

.modal {
  margin-top: 16px;
  font-family: $font-proxima;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  height: 48px;
  border: 2px solid $color-white;
  border-radius: 4px;
  padding: 16px;
  color: $color-white;
  background: transparent;
  width: 100%;
  &:hover {
    color: $color-dark-charcoal;
    background: #e4e5e3;
    border-color: #e4e5e3;
    svg > path {
      fill: $color-dark-charcoal;
    }
  }
  &:focus {
    border-color: #e4e5e3;
    outline: 2px dashed #787878;
    background: #e4e5e3;
    color: $color-dark-charcoal;
    svg > path {
      fill: $color-dark-charcoal;
    }
  }
}

.nav {
  border-radius: 3px;
  background: $color-dark-blue;
  font-family: $font-proxima;
  font-weight: 600;

  &:hover {
    border: 2px solid $color-dark-blue;
    background: #113c72;
    color: $color-white;
    svg > path {
      fill: $color-white;
    }
  }
}

.acp {
  border: 2px solid $color-dark-blue;
  padding: 16px;
  width: 244px;
  height: 48px;
  border-radius: 4px;
  background: $color-dark-blue;
  font-family: $font-proxima;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  &:hover {
    background-color: #113c72;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
  &:focus {
    outline: 2px dashed #787878;
  }
}
